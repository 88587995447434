import { Sidebar as UiKitSidebar } from '@ws/shared/components';
import { getResolution, isMobile } from '@ws/shared/utils';

export function Sidebar() {
  const resolution = getResolution();
  const mobile = resolution && isMobile(resolution);
  const sidebarVariant = mobile ? 'drawer' : 'standard';

  return (
    <UiKitSidebar.Container
      isOpen={false}
      variant={sidebarVariant}
      onOpen={() => {}}
      onClose={() => {}}
    ></UiKitSidebar.Container>
  );
}
