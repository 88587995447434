import { classes } from 'classifizer';
import { PropsWithChildren } from 'react';

import styles from './Page.module.css';

export interface IPageProps extends PropsWithChildren {
  className?: string;
}

export function Page({ className, children }: IPageProps) {
  return <div className={classes(styles['page'], className)}>{children}</div>;
}
