import { useQuery } from '@tanstack/react-query';

import { getResourceManager } from '../ResourceManager';

export function useFetchPreviewByLinkKey(linkKey: string) {
  return useQuery({
    staleTime: Infinity,
    placeholderData: null,
    queryKey: ['useFetchPreviewByLinkKey', linkKey],
    queryFn: async ({ queryKey }) => {
      const manager = getResourceManager();
      const response = await manager.PreviewService.getPreviewByLinkKey(queryKey[1]);

      if (!response.isOk || !response.preview) {
        return null;
      }

      return response.preview;
    },
  });
}
