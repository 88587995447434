import { createReducer } from '@reduxjs/toolkit';
import { IAction } from '@ws/shared/types';

import {
  IAuthStatusAction,
  IUserGetInfoSuccess,
  IUserState,
  USER_GET_INFO,
  USER_SET_AUTH_STATUS,
} from './user.types';

const INITIAL_STATE: IUserState = {
  isLoading: true,
  isAuth: false,
  info: null,
  errorMessage: null,
};

export const userReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(USER_GET_INFO.REQUEST, (state: IUserState) => {
      state.isLoading = true;
    })
    .addCase(USER_GET_INFO.SUCCESS, (state: IUserState, action: IAction<IUserGetInfoSuccess>) => {
      state.isLoading = false;
      state.info = action.payload?.customer || null;
      state.isAuth = true;
    })
    .addCase(USER_GET_INFO.FAILURE, (state: IUserState) => {
      state.isLoading = false;
    })
    .addCase(USER_SET_AUTH_STATUS, (state: IUserState, action: IAction<IAuthStatusAction>) => {
      state.isAuth = action.payload?.isAuth || false;

      if (!state.isAuth && state.info) {
        state.info = null;
      }
    });
});
