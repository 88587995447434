import { createApiActionError } from '@ws/shared/utils';
import { toast } from 'react-toastify';
import { call, delay, put, takeEvery, takeLeading } from 'typed-redux-saga';

import { getResourceManager } from '../../resources/ResourceManager';

import { getUserInfo, logoutUser } from './user.actions';
import { IUserGetInfoSuccess, USER_GET_INFO, USER_LOGOUT } from './user.types';

function* getUserInfoSaga() {
  yield* put(getUserInfo.request());

  try {
    const resourceManager = getResourceManager();
    const getInfo = resourceManager.UserService.getInfo.bind(resourceManager.UserService);
    const checkData = yield* call(getInfo);

    if (checkData.isOk && checkData.customer) {
      yield* put(getUserInfo.success<IUserGetInfoSuccess>({ customer: checkData.customer }));
    } else {
      yield* put(
        getUserInfo.failure(null, { errorMessage: 'Error occurred during user data fetch!' }),
      );
    }
  } catch (error) {
    yield* put(getUserInfo.failure(null, createApiActionError(error)));
  }
}

function* logoutSaga() {
  yield* put(logoutUser.request());

  try {
    const resourceManager = getResourceManager();
    const logoutFunc = resourceManager.AuthService.logout.bind(resourceManager.AuthService);
    const { isOk } = yield* call(logoutFunc);

    if (isOk) {
      toast.success('Successfully logged out!');
    } else {
      toast.error('Something went wrong!');
    }

    yield* delay(300);
    yield* put(getUserInfo());
  } catch (error) {
    yield* put(logoutUser.failure(null, createApiActionError(error)));
  }
}

export function* userWatch() {
  yield* takeLeading(USER_GET_INFO.DEFAULT, getUserInfoSaga);
  yield* takeEvery(USER_LOGOUT.DEFAULT, logoutSaga);
}
