import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalsContextManager } from '@ws/shared/components';
import { Provider as ReduxProvider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { App } from './components/App';
import styles from './index.module.css';
import { store as reduxStore } from './redux';

import '@ws/shared/src/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

export function Root() {
  return (
    <ReduxProvider store={reduxStore}>
      <ModalsContextManager>
        <QueryClientProvider client={queryClient}>
          <App />

          <ToastContainer
            autoClose={3000}
            draggable={true}
            position={toast.POSITION.BOTTOM_RIGHT}
            className={styles['custom-toaster']}
          />
        </QueryClientProvider>
      </ModalsContextManager>
    </ReduxProvider>
  );
}
