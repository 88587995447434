import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';

import { generalSaga } from '../general';
import { userWatch } from '../user/user.saga';

export const sagaMiddleware = createSagaMiddleware({});

export function* rootSaga() {
  yield* all([generalSaga(), userWatch()]);
}

export function initSagas() {
  sagaMiddleware.run(rootSaga);
}
