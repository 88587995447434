import { createBrowserRouter } from 'react-router-dom';

import { PreviewPage } from '../../pages';
import { ReviewPage } from '../../pages/ReviewPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <div>Нет доступных предпоказов</div>,
  },
  {
    path: '/link/:linkKey',
    element: <PreviewPage />,
  },
  {
    path: '/link/:linkKey/review',
    element: <ReviewPage />,
  },
]);
