import {
  CollectionCompilerTemplate,
  CompilerContent,
  CompilerContentBlock,
  Header,
  Input,
} from '@ws/shared';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '../../components/Page';
import { Sidebar } from '../../components/Sidebar';
import { useFetchPreviewByLinkKey } from '../../resources/hooks/useFetchPreviewByLinkKey';

import styles from './PreviewPage.module.css';

export function PreviewPage() {
  const { linkKey } = useParams<{ linkKey: string }>();

  const {
    data: preview,
    isLoading,
    isPlaceholderData,
  } = useFetchPreviewByLinkKey(linkKey as string);

  const contentRef = useRef<HTMLDivElement | null>(null);

  if (isLoading || isPlaceholderData) {
    return <>Loading...</>;
  }

  if (!preview) {
    return <>{'Предпоказ недоступен'}</>;
  }

  return (
    <Page className={styles['preview-page']}>
      <Sidebar />
      <div className={styles['preview-page__playground-wrapper']}>
        <Header
          className={styles['preview-page__header']}
          title={<Input variant="shapeless" value={preview.title} />}
        />
        <div ref={contentRef} className={styles['preview-page__content']}>
          <CollectionCompilerTemplate
            compiledNodes={preview.content.map((part) => (
              <CompilerContentBlock key={part.id} noNoteHighlight>
                <CompilerContent entity={part} />
              </CompilerContentBlock>
            ))}
          />
        </div>
      </div>
    </Page>
  );
}
