import { createNewWrapElem } from './createNewWrapElement';

export function wrapSelectedTextNodes(
  elemCollections: Node[][],
  commentId: string,
  className: string,
) {
  elemCollections.forEach((selectionParts) => {
    /*
     * отметка начального индекса
     * содержит индекс узла, с которого начинается череда узлов с одинаковым родителем
     */
    let fromIdx = -1;
    selectionParts.forEach((engagedNode, idx, array) => {
      const { elem: wrapper } = createNewWrapElem(commentId, className);

      const next = array[idx + 1];

      /*
       * проверяем, не конец ли массива
       * проверяем, относится ли следующий узел к череде узлов с одним родителем
       * оборачиваем узлы с общим родителем в одну обёртку
       * обнуляем отметку начального индекса
       */
      if (!next || next.parentNode !== engagedNode.parentNode) {
        if (fromIdx !== -1 && fromIdx !== idx) {
          const elementsToWrap = array.slice(fromIdx, idx + 1);
          (elementsToWrap[0] as Element).before(wrapper);
          elementsToWrap.forEach((child) => wrapper.appendChild(child));

          fromIdx = -1;
          return;
        }
      }

      /*
       * если следующий узел есть и у него такой же родитель
       * если отметка начального индекса не ровна -1
       * отмечаем нынешний узел как начало череды узлов с общим родителем
       */
      if (
        next &&
        engagedNode.nodeName.toLowerCase() !== 'p' &&
        next.parentNode === engagedNode.parentNode
      ) {
        if (fromIdx === -1) {
          fromIdx = idx;
        }
        return;
      }

      /*
       * если предыдущий узел есть
       * если предыдущий узел является предком нынешнего узла
       * пропускаем его, так как родителя уже обернули
       * position 10 обозначает наличие двух факторов:
       * prevNode и предшествующий в переборе узел, и родительский узел
       */
      // const prevNode = array[idx - 1];
      // if (prevNode) {
      //   const position = engagedNode.compareDocumentPosition(prevNode);
      //
      //   if (position === Node.DOCUMENT_POSITION_CONTAINS || position === 10) {
      //     return;
      //   }
      // }

      /*
       * Если узел не является текстовым узлом, он является теговым элементом
       * мы оборачиваем всех его потомков, но не его самого
       * обёртку вставляем как единственного потомка
       */
      if (engagedNode.nodeType !== Node.TEXT_NODE) {
        const children = Array.from(engagedNode.childNodes);
        children.forEach((child) => {
          wrapper.appendChild(child);
        });
        engagedNode.appendChild(wrapper);

        return;
      }

      /*
       * Если все остальные случаи не подходят и узел является текстовым
       * мы вставляем перед ним обёртку, а сам узел делаем потомком обёртки
       */
      (engagedNode as Text).before(wrapper);
      wrapper.appendChild(engagedNode);
    });
  });
}
