export function getSelectedTextNodes() {
  const returnArray: Node[][] = [];

  const selection = window.getSelection();

  if (!selection) {
    return returnArray;
  }
  for (let rangeNumber = selection.rangeCount - 1; rangeNumber >= 0; rangeNumber--) {
    const rangeNodes = [];
    const range = selection.getRangeAt(rangeNumber);

    /*
     * Если и начало, и конец селекшна являются одним и тем же элементом
     * и при этом это текстовый элемент
     */
    if (
      range.startContainer === range.endContainer &&
      range.endContainer.nodeType === Node.TEXT_NODE
    ) {
      /*
       * берём текст от начала и до конца выделения
       */
      (range.startContainer as Text).splitText(range.endOffset);
      const textNode = (range.startContainer as Text).splitText(range.startOffset);
      rangeNodes.push(textNode);
    } else {
      /*
       * Создаём итераратов узлов
       * начиная с самого общего предка для начального и конечного контейнеров рейнжа селекшна
       * показываем все узлы, так как нам нужны только SHOW_TEXT и SHOW_ELEMENT, но так указать нельзя
       * сами отфильтровываем текст и элементы
       * проверяем, что нынешний узел только последователь (в переборе) стартового узла, но не его потомок
       * проверяем, что нынешний узел только предшественник (в переборе) конечного узла, но не его предок
       */
      const textIterator = document.createNodeIterator(
        range.commonAncestorContainer,
        // NodeFilter.SHOW_ELEMENT,
        NodeFilter.SHOW_ALL,
        (node) => {
          const isCorrectNode =
            node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE;
          const positionAccordingToStartContainer = node.compareDocumentPosition(
            range.startContainer,
          );
          const positionAccordingToEndContainer = node.compareDocumentPosition(range.endContainer);

          // const otherConditions = node.parentNode?.nodeName !== 'MARK';

          if (
            isCorrectNode &&
            // otherConditions &&
            positionAccordingToStartContainer === Node.DOCUMENT_POSITION_PRECEDING &&
            positionAccordingToEndContainer === Node.DOCUMENT_POSITION_FOLLOWING
          ) {
            return NodeFilter.FILTER_ACCEPT;
          }

          return NodeFilter.FILTER_REJECT;
        },
      );

      // let node = textIterator.nextNode();
      let node: Node | null = null;

      /*
       * Если это <br /> или не пустой элемент
       * добавляем его в массив
       */
      while ((node = textIterator.nextNode())) {
        if (node.nodeType !== Node.TEXT_NODE) {
          const isTopLevelContainer = (node as Element).classList.contains(
            'compiler-content-block',
          );
          const isContentLevelContainer = (node as Element).classList.contains('note_content');

          if (isTopLevelContainer || isContentLevelContainer) {
            continue;
          }
        }

        if ((node.textContent || '').trim() !== '' || node.nodeName === 'BR') {
          rangeNodes.push(node);
        }
      }

      /*
       * Если конечный контейнер ялвяется текстовым элементом
       * отрезаем нужное количество текста и добавляем в массив
       */
      if (range.endContainer.nodeType === Node.TEXT_NODE) {
        (range.endContainer as Text).splitText(range.endOffset);
        rangeNodes.push(range.endContainer);
      }

      /*
       * Если стартовый контейнер ялвяется текстовым элементом
       * отрезаем нужное количество текста и добавляем в массив
       */
      if (range.startContainer.nodeType === Node.TEXT_NODE) {
        rangeNodes.unshift((range.startContainer as Text).splitText(range.startOffset));
      }
    }

    returnArray.unshift(rangeNodes);
  }

  return returnArray;
}
