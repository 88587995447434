import {
  Button,
  CollectionCompilerTemplate,
  CompilerContent,
  CompilerContentBlock,
  Header,
  Input,
} from '@ws/shared';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { Page } from '../../components/Page';
import { Sidebar } from '../../components/Sidebar';
import { useFetchPreviewByLinkKey } from '../../resources/hooks/useFetchPreviewByLinkKey';
import { delay } from '../../utils/delay';

import styles from './ReviewPage.module.css';
import { highlightSelectedText } from './textSelectionHelpers/highlightSelectedText';

export function ReviewPage() {
  const { linkKey } = useParams<{ linkKey: string }>();

  const {
    data: preview,
    isLoading,
    isPlaceholderData,
  } = useFetchPreviewByLinkKey(linkKey as string);

  const contentRef = useRef<HTMLDivElement | null>(null);
  const [commentId, setCommentId] = useState<string | null>(null);

  function handleClick() {
    const sel = window.getSelection();
    if (sel && !sel.isCollapsed) {
      const newCommentId = v4();
      highlightSelectedText(newCommentId, styles['highlighted']);

      return newCommentId;
    }

    return null;
  }

  const deCall = delay(1000, (event: any) => {
    console.log('event', event);
    const createdCommentId = handleClick();
    setCommentId(createdCommentId);

    const list = Array.from(document.querySelectorAll(`[data-comment-id="${createdCommentId}"]`));
    console.log('list', list.length);
  });

  useEffect(() => {
    document.onselectionchange = function (event) {
      if (commentId) {
        const notSavedElems = document.querySelectorAll(`[data-comment-id="${commentId}"]`);
        console.log('notSavedElems', notSavedElems);

        notSavedElems.forEach((elem) => {
          elem.parentNode?.replaceChild(document.createTextNode(elem.innerHTML), elem);
        });
      }
      deCall(event);
    };
  }, [deCall, commentId]);

  if (isLoading || isPlaceholderData) {
    return <>Loading...</>;
  }

  if (!preview) {
    return <>{'Предпоказ недоступен'}</>;
  }

  return (
    <Page className={styles['preview-page']}>
      <Sidebar />
      <div className={styles['preview-page__playground-wrapper']}>
        <Header
          className={styles['preview-page__header']}
          title={<Input readOnly variant="shapeless" value={preview.title} />}
          after={
            <Button className={styles['preview-page__hl-button']} onClick={handleClick}>
              Highlight
            </Button>
          }
        />
        <div ref={contentRef} className={styles['preview-page__content']}>
          <CollectionCompilerTemplate
            compiledNodes={preview.content.map((part) => (
              <CompilerContentBlock key={part.id} noNoteHighlight>
                <CompilerContent entity={part} />
              </CompilerContentBlock>
            ))}
          />
        </div>
      </div>
    </Page>
  );
}
