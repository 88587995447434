import { IAction, TError } from '../../types';

import { createAction, TInnerActionCreater } from './createAction';
import { TApiActionTypes } from './createApiActionTypes';

export type TApiActions = {
  request<RT>(payload?: RT | null, error?: TError): IAction<RT>;
  success<ST>(payload?: ST | null, error?: TError): IAction<ST>;
  failure<TT>(payload?: TT | null, error?: TError): IAction<TT>;
};

export type TCombinedAction<T> = TInnerActionCreater<T> & TApiActions;

export const createApiActions = <T>(type: TApiActionTypes): TCombinedAction<T> => {
  const action = createAction<T>(type.DEFAULT);

  (action as TCombinedAction<T>).request = createAction(type.REQUEST);
  (action as TCombinedAction<T>).success = createAction(type.SUCCESS);
  (action as TCombinedAction<T>).failure = createAction(type.FAILURE);

  return action as TCombinedAction<T>;
};
