import { Fetcher } from '@ws/shared/utils';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Root } from './Root';
import './index.css';
import { initConfig, TConfig } from './config/config';
import { store } from './redux';
import { setUserAuthStatus } from './redux/user';
import { initResources } from './resources';

async function fetchConfig() {
  const fetcher = new Fetcher();
  return fetcher
    .fetch<TConfig>({ url: '/config/config.json', isExternal: true })
    .then((fileData) => {
      initConfig(fileData.data);
    });
}

function renderApp(Component: () => JSX.Element) {
  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <StrictMode>
      <Component />
    </StrictMode>,
  );
}

function handleUnAuth() {
  if (store.getState().user.isAuth) {
    store.dispatch(setUserAuthStatus({ isAuth: false }));
  }
}

async function InitApp() {
  await fetchConfig();

  initResources(handleUnAuth);

  renderApp(Root);
}

InitApp();
