export function delay<T>(wait: number, func: (...args: T[]) => void) {
  let timeout: any;

  return function (...args: T[]) {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      func.apply(func, args);
    }, wait);
  };
}
