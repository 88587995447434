import { v4 } from 'uuid';

export function createNewWrapElem(commentId: string, className?: string) {
  const id = v4();

  const elem = document.createElement('mark');
  elem.id = id;
  elem.dataset.commentId = commentId;

  if (className) {
    elem.classList.add(className);
  }

  return {
    elem,
    id,
  };
}
