import { createReducer } from '@reduxjs/toolkit';
import { IAction } from '@ws/shared/src/types';

import { GENERAL_SET, IGeneralSetAction, IGeneralState } from './general.types';

const INITIAL_STATE: IGeneralState = {};

export const generalReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(GENERAL_SET, (state: IGeneralState, action: IAction<IGeneralSetAction>) => {
    return { ...state, ...action.payload };
  });
});
