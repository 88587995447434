import { SplashScreen } from '@ws/shared/components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { TAppState } from '../../redux';
import { getUserInfo } from '../../redux/user';

import styles from './App.module.css';
import { router } from './router';

export function App() {
  const { isLoading } = useSelector((state: TAppState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <div className={styles['app']}>
      <RouterProvider router={router} />
    </div>
  );
}
