import { IAction, TError } from '../../types';

export type TActionCreaterOptions = {
  trace?: boolean;
};

export type TSimpleActionOptions = {
  trace?: boolean;
};

export type TInnerActionCreater<T> = (
  payload?: T | null,
  error?: TError,
  options?: TSimpleActionOptions,
) => IAction<T>;

export function createAction<T>(
  type: string,
  outerOptions?: TActionCreaterOptions,
): TInnerActionCreater<T> {
  return function innerActionCreator(
    payload?: T | null,
    error?: TError,
    options?: TSimpleActionOptions,
  ): IAction<T> {
    if (options?.trace || outerOptions?.trace) {
      console.trace(type);
    }

    return {
      type,
      payload,
      error,
    };
  };
}
